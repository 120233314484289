// 'use client';

// import Image from 'next/image';
// import Link from 'next/link';
// import { useRouter } from 'next/navigation';
// import React, { useState, useEffect } from 'react';

// interface GameCardProps {
//   title: string;
//   imageUrl: string;
// }

// const GameCard: React.FC<GameCardProps> = ({ title, imageUrl }) => {
//   const router = useRouter();
//   const [imageLoaded, setImageLoaded] = useState<boolean>(false);
//   const [imageError, setImageError] = useState<boolean>(false);
//   const [isNavigating, setIsNavigating] = useState<boolean>(false);

//   useEffect(() => {
//     const img = new window.Image();
//     img.src = imageUrl;
//     img.onload = () => setImageLoaded(true);
//     img.onerror = () => setImageError(true);
//   }, [imageUrl]);

//   const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>) => {
//     e.preventDefault();
//     setIsNavigating(true);
//     router.push(`/games/${encodeURIComponent(title)}`);
//   };

//   return (
//     <div className="bg-white rounded-lg shadow-md overflow-hidden">
//       <Link href={`/games/${encodeURIComponent(title)}`} onClick={handleNavigation}>
//         <div className="relative w-full h-40">
//           {!imageLoaded && !imageError && (
//             <div className="absolute inset-0 bg-gray-200 animate-pulse"></div>
//           )}
//           <Image
//             src={imageError ? '/default-game-image.png' : imageUrl}
//             alt={title}
//             fill
//             sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
//             style={{ objectFit: 'cover' }}
//             className={`transition-opacity duration-300 ${imageLoaded || imageError ? 'opacity-100' : 'opacity-0'}`}
//             onError={() => setImageError(true)}
//             onLoad={() => setImageLoaded(true)}
//           />
//           {isNavigating && (
//             <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//               <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
//             </div>
//           )}
//         </div>
//         <h3 className="p-4 text-lg font-semibold">{title}</h3>
//       </Link>
//     </div>
//   );
// };

// export default GameCard;

//技术细节 一定要用<a>标签，不能用<Link>标签，否则会导致flash游戏页面出现ruffle迟迟不加载的情况。

import Image from 'next/image';
//import Link from 'next/link';
import React, { useState } from 'react';

interface GameCardProps {
  title: string;
  imageUrl: string;
}

const GameCard: React.FC<GameCardProps> = ({ title, imageUrl }) => {
  const [imageError, setImageError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = () => {
    setIsLoading(true);
  };

  // return (
  //   <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow relative">
  //     <Link 
  //       href={`/games/${encodeURIComponent(title)}`} 
  //       onClick={handleClick}
  //       className="block"
  //     >
  //       <div className="relative w-full h-40">
  //         <Image
  //           src={imageError ? '/default-game-image.png' : imageUrl}
  //           alt={title}
  //           fill
  //           sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
  //           style={{ objectFit: 'cover' }}
  //           className="transition-opacity duration-300"
  //           onError={() => setImageError(true)}
  //           priority={true}
  //           placeholder="blur"
  //           blurDataURL="/default-game-image.png"
  //         />
  //       </div>
  //       <h3 className="p-4 text-lg font-semibold">{title}</h3>
        
  //       {/* 加载状态指示器 */}
  //       {isLoading && (
  //         <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
  //           <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
  //         </div>
  //       )}
  //     </Link>
  //   </div>
  // );
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow relative">
      <a
        href={`/games/${encodeURIComponent(title)}`} 
        onClick={handleClick}
        className="block"
      >
        <div className="relative w-full h-40">
          <Image
            src={imageError ? '/default-game-image.png' : imageUrl}
            alt={title}
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            style={{ objectFit: 'cover' }}
            className="transition-opacity duration-300"
            onError={() => setImageError(true)}
            priority={true}
            placeholder="blur"
            blurDataURL="/default-game-image.png"
          />
        </div>
        <h3 className="p-4 text-lg font-semibold">{title}</h3>
        
        {/* 加载状态指示器 */}
        {isLoading && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
          </div>
        )}
      </a>
    </div>
  );
};

export default GameCard;